(function() {
    'use strict';
    /*eslint-disable dot-notation */

    angular
        .module('spidwebApp')
        .provider('envService', envServiceProvider);


    function envServiceProvider () {

        this.$get = angular.noop;

        var currentEnvironment;

        var service = {
            $get: function () {
                return service;
            },
            properties: envProperties,
            wediaProperties: wediaEnvironments,
            getProperty: function (key) {
                return service.properties[key];
            },
            getCurrentEnvironmentProperty: function (key) {
                return service.properties[service.getCurrentEnvironment()][key];
            },
            setCurrentEnvironment: function (env) {
                currentEnvironment = env;
            },
            getCurrentEnvironment: function () {
                return currentEnvironment;
            },
            getVersion: function () {
                return service.properties['version'];
            },
            getUrl: function (key) {
                return service.properties['urls'][key];
            },
            getGAAccount: function () {
                return service.properties[service.getCurrentEnvironment()]['GAAccount'];
            },
            getBeamerAccount: function () {
                return service.properties[service.getCurrentEnvironment()]['beamerAccount'];
            },
            getContentsURL: function () {
                return service.properties[service.getCurrentEnvironment()]['contentsURL'];
            },
            getServerContext: function () {
                return service.properties[service.getCurrentEnvironment()]['serverContext'];
            },
            getServerApiContext: function () {
                return this.getServerContext() + service.properties[service.getCurrentEnvironment()]['serverApiContext'] + '/';
            },
            getAuthUser: function () {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('authUser');
            },
            getUserContext: function () {
                return service.getBaseUrl() + service.getServerApiContext() + 'user';
            },
            getBaseUrl: function () {
                return service.properties[service.getCurrentEnvironment()]['base'];
            },
            getTreeUrl: function () {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('tree');
            },
            getUrlCountriesListUrl: function () {
                return service.getCountryContext() + '/locales';
            },
            getUrlLanguageDependencies: function () {
                return service.getCountryContext() + '/languageForDisplay';
            },
            getUrlRightToLeftLocale: function () {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('urlRightToLeftLocale');
            },
            getGenderUrl: function () {
                return service.getOfferDatasContext() + '/genders';
            },
            getProductNaturesUrl: function () {
                return service.getOfferDatasContext() + '/productNatures';
            },
            getSuperModelModifyProductNatureURL: function () {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('superModelEditProductNature');
            },
            getOfferDatasContext: function () {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('offerdatas');
            },
            getTranslationUrl: function () {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('translation');
            },
            getTranslationV2Url: function () {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('translation') + '/v2';
            },
            getBrandUniverseUrl: function () {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('brandUniverse');
            },
            getSportsGroupsUrl: function () {
                return service.getOfferDatasContext() + '/sportsGroups';
            },
            getSportsUrl: function () {
                return service.getOfferDatasContext() + '/sports';
            },
            getMassActionsContext: function () {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('massActionsContext');
            },
            getReconductionModelUrl: function (modelId, locale) {
                return service.getModelsContext() + '/' + modelId + '/' + locale + '/reconduction';
            },
            getPixlUrl: function () {
                return service.getBaseUrl() + service.getServerApiContext() + 'pixl';
            },
            getParametersURL: function () {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('parametersContext');
            },
            getFluxContext: function () {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('fluxContext');
            },
            getTranslationMappingsContext: function () {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('translationMappingsContext');
            },
            getLogoutUrl: function () {
                return service.getBaseUrl() + service.getServerContext() + service.getUrl('logout');
            },
            getRightsManagementLink: function () {
                return service.getBaseUrl() + service.getServerContext() + service.getUrl('rightsManagementLink');
            },
            getUrlBrandLdap: function () {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('brandLdap');
            },
            getCountryContext: function () {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('countryContext');
            },
            getTranslationJobUrl: function (alfrescoId) {
                return service.getTranslationJobsContext() + '/' + alfrescoId;
            },
            getMassRepublicationtUrl: function (modelCode) {
                return service.getModelsContext() + '/' + modelCode + '/republish';
            },
            getDuplicateMediasUrl: function (modelNodeId) {
                return service.getModelsContext() + '/' + modelNodeId + '/duplicateMedias';
            },
            getArticlesByModelUrl: function (modelCode) {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('modelsContext') + '/' + modelCode + '/articles';
            },
            getBrightcoveUrl: function () {
                return service.getUrl('brightcoveUrl');
            },
            getNotationUnitsUrl: function () {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('notationUnits');
            },
            getMaterialsUrl: function () {
                return service.getOfferDatasContext() + '/compositions';
            },
            getProductPartsUrl: function () {
                return service.getOfferDatasContext() + '/partsOfProduct';
            },
            getCareInstructionsUrl: function () {
                return service.getOfferDatasContext() + '/careInstructions';
            },
            getCareSymbolsUrl: function () {
                return service.getOfferDatasContext() + '/careSymbols';
            },
            getWarningsUrl: function () {
                return service.getOfferDatasContext() + '/warnings';
            },
            getZdUrl: function () {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('zd');
            },
            getCartContent: function () {
                return this.getWediaEnvironmentProperty('cartContent');
            },
            getCdnBaseUrl: function () {
                return this.getWediaEnvironmentProperty('cdnBaseUrl');
            },
            getVideoAvailableLanguages: function () {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('videoAvailableLanguagesURL');
            },
            getWediaEnvironmentProperty: function(wediaProperty) {
                var wediaEnvironment = this.getCurrentEnvironmentProperty('wediaEnvironment');
                return service.wediaProperties[wediaEnvironment][wediaProperty];
            },
            getSeasonsContext: function() {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('seasonsContext');
            },
            getBrandURL: function() {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('brandContext');
            },
            getModelsContext: function() {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('modelsContext');
            },
            getModelSeasonsContext: function() {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('modelSeasonsContext');
            },
            getSuperModelsContext: function() {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('superModelsContext');
            },
            getProductCardContext: function() {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('productCardContext');
            },
            getSuperModelSeasonsContext: function() {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('superModelSeasonsContext');
            },
            getSuperModelSeasonEventsContext: function() {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('superModelSeasonEvents');
            },
            getUserBenefitContext: function() {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('userBenefitContext');
            },
            getTranslationJobsContext: function() {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('translationJobsContext');
            },
            getTransdiagTranslationStateUrl: function (modelCode, dateSale, language) {
                const transdiagBaseUrl = service.properties[service.getCurrentEnvironment()]['transdiagBaseUrl'];
                return transdiagBaseUrl + service.getUrl('transdiagTranslationState') + '?modelCode=' + modelCode + '&dateSale='
                                + dateSale + '&language=' + language + '&closedProjects=true' + '&submit=true';
            },
            getAllSeasonByCodeModelUrl: function () {
                return service.getProductCardContext() ;
            },
            getPropagationContext: function() {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('propagationContext');
            },
            getVersionsContext: function() {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('versionsContext');
            },
            getProductBriefReferenceUrl: function() {
                return service.getCurrentEnvironmentProperty('productBriefBaseUrl') + service.getUrl('productBriefReferenceEndpoint');
            },
            getSpoUpdateSmUrl: function() {
                return service.getCurrentEnvironmentProperty('spoBaseUrl') + service.getUrl('spoUpdateSmEndpoint');
            },
            getSuperModelTimelineUrl: function() {
                return service.getBaseUrl() + service.getServerApiContext() + service.getUrl('timeline') + '/superModels/:code';
            },
            getInvalidUrl: function() {
                return service.getBaseUrl() + service.getServerApiContext() + 'ERROR_500';
            },
            getLocaleLabUrl: function() {
                return service.getCurrentEnvironmentProperty('localeLabUrl');
            },
            getAgecUploadUrl: function() {
                return service.getBaseUrl() + service.getServerApiContext() + 'agec/upload';
            }
        };

        return service;
    }

})();
